import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './common/Spinner';

const Login = lazy(() => import('./user/Login'));
const Home = lazy(() => import('./home/Home'));
const OrderHistory = lazy(() => import('./order/OrderHistory'));
const ProductManagement = lazy(() => import('./product/ProductManagement'));
const PurchaseOrder = lazy(() => import('./purchaseOrder/PurchaseOrder'));
const IngredientsInventory = lazy(() => import('./inventory/IngredientsInventory'));
const BulkEditInventory = lazy(() => import('./inventory/BulkEditInventory'));
const Offer = lazy(() => import('./offer/OfferHistory'));
const OfferHome = lazy(() => import('./offer/Offer'));
// const ManagerList = lazy(() => import('./user/ManagerList'));
const OrderEdit = lazy(() => import('./OrderEdit/Order'));
const TerminalManagement = lazy(() => import('./home/TerminalManagement'));
const Reports = lazy(()=>import('./reports/Reports'))
class AppRoutes extends Component {

  state = {
    userToken: {},
    user: ""
  }

  componentDidMount() {
    this.getToken();
    this.getUserRole();
  }

  getToken = () => {
    let token = localStorage.getItem('USER');
    this.setState({
      userToken: {
        "token": token
      }
    })
  }

  getUserRole = () => {
    let user = localStorage.getItem("userRole");
    this.setState({ user: user })
  }

  render() {
    const { userToken } = this.state;
    return (
      <Suspense fallback={<Spinner />}>

        <Switch>
          <Route exact path="/user/login" component={Login} />
          {/* <Route exact path="/user/managers" component={ManagerList} /> */}
          {/* <Route exact path="/terminalManagement" component={TerminalManagement} /> */}
          <Route exact path="/home" component={Home} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/orderHistory" component={OrderHistory} />
          <Route exact path="/productManagement" component={ProductManagement} />
          <Route exact path="/offerhistory" component={Offer} />
          <Route exact path="/offer" component={OfferHome} />
          <Route exact path="/purchaseorder" component={PurchaseOrder} />
          <Route exact path="/inventory" component={IngredientsInventory} />
          <Route exact path="/inventory-bulk-edit" component={BulkEditInventory} />
          <Route exact path="/orderedit" component={OrderEdit} />
          {userToken.token !== null && userToken.token !== "undefined" ?
            <Redirect to="/home" /> :
            <Redirect to="/user/login" />
          }
        </Switch>

      </Suspense>
    );
  }
}


export default AppRoutes;