import baseState from '../store/baseState';
import {
    GET_OFFER_LIST, GET_OFFER_BY_ID, CREATE_OFFER, HSN_LIST, GSL_SLAB_LIST
} from '../actions/action';

export default (state = baseState.reports, { payload, type, error }) => {
    switch (type) {

        case HSN_LIST.REQUEST:
            return {
                ...state,
            };

        case HSN_LIST.SUCCESS:
            return {
                ...state,
                reportList: payload.data.data
            };

        case GSL_SLAB_LIST.REQUEST:
            return {
                ...state,
            };

        case GSL_SLAB_LIST.SUCCESS:
            return {
                ...state,
                reportList: payload.data.data
            };
        default:
            return state;
    }
};