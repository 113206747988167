import baseState from '../store/baseState';
import {
  CATEGORY_LIST, PRODUCT_LIST, CLONE_CATEGORY_LIST, CREATE_PRODUCT, UPDATE_PRODUCT, GET_PRODUCT_INGREDIENTS, GET_PRODUCT_BY_ID, UPDATE_PRODUCT_INGREDIENTS, UPDATE_CATEGORY, PRODUCT_CATEGORY_LIST,
  SUB_CATEGORY_LIST, UPDATE_SUB_CATEGORY, CREATE_SUB_CATEGORY, PRODUCT_LIST_By_SEARCH
} from '../actions/action';

export default (state = baseState.productData, { payload, type, error }) => {
  switch (type) {

    case CATEGORY_LIST.REQUEST:
      return {
        ...state,
      };

    case CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        categoryList: payload.data.data
      };
    case PRODUCT_CATEGORY_LIST.REQUEST:
      return {
        ...state,
      };

    case PRODUCT_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        productCategoryList: payload.data.data
      };

    case SUB_CATEGORY_LIST.REQUEST:
      return {
        ...state,
      };

    case SUB_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        subCategoryList: payload.data.data
      };


    case PRODUCT_LIST.REQUEST:
      return {
        ...state,
      };

    case PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        productList: payload.data
      };
    case PRODUCT_LIST_By_SEARCH.REQUEST:
      return {
        ...state,
      };
    case PRODUCT_LIST_By_SEARCH.SUCCESS:
      return {
        ...state,
        productList: payload.data
      };

    case CLONE_CATEGORY_LIST.REQUEST:
      return {
        ...state,
      };

    case CLONE_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        cloneCategoryList: payload.data.data
      };

    case UPDATE_PRODUCT.REQUEST:
      return {
        ...state,
      };

    case UPDATE_PRODUCT.SUCCESS:
      return {
        ...state,
        updateProduct: payload.data.data
      };

    case GET_PRODUCT_INGREDIENTS.REQUEST:
      return {
        ...state,
      };

    case GET_PRODUCT_INGREDIENTS.SUCCESS:
      return {
        ...state,
        productIngredients: payload.data.data
      };

    case UPDATE_PRODUCT_INGREDIENTS.REQUEST:
      return {
        ...state,
      };

    case UPDATE_PRODUCT_INGREDIENTS.SUCCESS:
      return {
        ...state,
        updatedproductIngredients: payload.data.data
      };

    case GET_PRODUCT_BY_ID.REQUEST:
      return {
        ...state,
      };

    case GET_PRODUCT_BY_ID.SUCCESS:
      return {
        ...state,
        productDetails: payload.data.data
      };

    case CREATE_PRODUCT.REQUEST:
      return {
        ...state,
      };

    case CREATE_PRODUCT.SUCCESS:
      return {
        ...state,
        createdProduct: payload.data.data
      };

    case UPDATE_CATEGORY.REQUEST:
      return {
        ...state,
      };

    case UPDATE_CATEGORY.SUCCESS:
      return {
        ...state,
        updateCategory: payload.data.data
      };
    case UPDATE_SUB_CATEGORY.REQUEST:
      return {
        ...state,
      };

    case UPDATE_SUB_CATEGORY.SUCCESS:
      return {
        ...state,
        updateSubCategory: payload.data.data
      };
    case CREATE_SUB_CATEGORY.REQUEST:
      return {
        ...state,
      };

    case CREATE_SUB_CATEGORY.SUCCESS:
      return {
        ...state,
        createdSubCategory: payload.data.data
      };

    default:
      return state;
  }
};