//POS production base URL.
//const BASE_URL = 'https://demo.insonix.com:9093/pos-pilot-api';

//POS dev base URL.
// const BASE_URL = 'http://103.223.12.194:9091';


//live 
// const BASE_URL = 'https://demo.insonix.com:9096/pos-pilot-api-dev';

// dev
// const BASE_URL = 'https://demo.insonix.com:9096/pos-crockery-dev';

//bmart production
const BASE_URL = 'https://demo.insonix.com:9093/pos-bmart';

export const getUserApiUrl = () => {
    let user = localStorage.getItem("userRole");

    if (user === "ROLE_MANAGER") {
        return BASE_URL + "/manager";
    } else if (user === "ROLE_ADMIN") {
        return BASE_URL + "/admin";
    }
}

export const getAuthUrl = () => {

   
    return BASE_URL + "/auth";
}