//Actions related to login, logout to Application
export const ACCOUNT__LOGIN = createActionType('ACCOUNT__LOGIN');
export const ACCOUNT__LOGOUT = createActionType('ACCOUNT__LOGOUT');

//define category module actions
export const CATEGORY_LIST = createActionType('CATEGORY_LIST');
export const PRODUCT_CATEGORY_LIST = createActionType('PRODUCT_CATEGORY_LIST');
export const SUB_CATEGORY_LIST = createActionType('SUB_CATEGORY_LIST');
export const CLONE_CATEGORY_LIST = createActionType('CLONE_CATEGORY_LIST');

//define offer module actions
export const CREATE_OFFER = createActionType('CREATE_OFFER');
export const GET_OFFER_LIST = createActionType('GET_OFFER_LIST');
export const GET_OFFER_BY_ID = createActionType('GET_OFFER_BY_ID');

//define order module actions
export const ORDER_LIST = createActionType('ORDER_LIST');
export const CREATE_UPDATE_ORDER = createActionType('CREATE_UPDATE_ORDER');
export const GET_ORDER_DETAILS_BY_ID = createActionType('GET_ORDER_DETAILS_BY_ID');

//define product module actions
export const PRODUCT_LIST = createActionType('PRODUCT_LIST');
export const PRODUCT_LIST_By_SEARCH = createActionType('PRODUCT_LIST_By_SEARCH');
export const CREATE_PRODUCT = createActionType('CREATE_PRODUCT');
export const UPDATE_PRODUCT = createActionType('UPDATE_PRODUCT');
export const PRODUCT_MASTERLIST = createActionType('PRODUCT_MASTERLIST');
export const GET_PRODUCT_INGREDIENTS = createActionType('GET_PRODUCT_INGREDIENTS');
export const GET_PRODUCT_BY_ID = createActionType('GET_PRODUCT_BY_ID');
export const UPDATE_PRODUCT_INGREDIENTS = createActionType('UPDATE_PRODUCT_INGREDIENTS');

//define order payment module actions
export const UPDATE_ORDER_PAYMENT = createActionType('UPDATE_ORDER_PAYMENT');
export const CURRENT_ORDER_DETAILS = createActionType('CURRENT_ORDER_DETAILS');
export const UPDATE_CATEGORY = createActionType('UPDATE_CATEGORY');
export const UPDATE_SUB_CATEGORY = createActionType('UPDATE_SUB_CATEGORY');
export const CREATE_SUB_CATEGORY = createActionType('CREATE_SUB_CATEGORY');

//define manager module actions
export const GET_MANAGER_LIST = createActionType('GET_MANAGER_LIST');
export const CREATE_MANAGER = createActionType('CRAETE_MANAGER');
export const GET_MANAGER_DETAILS_BY_ID = createActionType('GET_MANAGER_DETAILS_BY_ID');
export const CHANGE_PROFILE_PASSWORD = createActionType('CHANGE_PROFILE_PASSWORD');

//define ingredient module actions
export const INGREDIENT_LIST = createActionType('INGREDIENT_LIST');
export const INGREDIENT_REPORT_LIST = createActionType('INGREDIENT_REPORT_LIST');
export const UPDATE_INGREDIENT = createActionType('UPDATE_INGREDIENT');
export const ADD_INGREDIENT = createActionType('ADD_INGREDIENT');
export const RESET_INGREDIENTS_QTY = createActionType('RESET_INGREDIENTS_QTY');

//define Purchase Order module actions
export const PO_LIST = createActionType('PO_LIST');
export const CREATE_PO = createActionType('CREATE_PO');
export const GET_PO_BY_ID = createActionType('GET_PO_BY_ID');
export const MOVE_PO = createActionType('MOVE_PO');
export const CANCEL_PO = createActionType('CANCEL_PO');
export const UPDATE_PO = createActionType('UPDATE_PO');

// define Reports actions
export const HSN_LIST = createActionType('HSN_LIST');
export const GSL_SLAB_LIST = createActionType('GSL_SLAB_LIST');


export function createAction({ action, headers = {}, type }) {
  return async (dispatch) => {
    dispatch({ type: type.REQUEST, headers });
    try {
      dispatch({ type: type.SUCCESS, headers, payload: await action() });
    } catch (error) {
      dispatch({ type: type.FAILURE, headers, error });
    }
  };
}
export function createActionType(id) {
  return {
    FAILURE: `${id}__FAILURE`,
    REQUEST: `${id}__REQUEST`,
    SUCCESS: `${id}__SUCCESS`,
  };
}