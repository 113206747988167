import { combineReducers } from 'redux';
import authData from './authentication';
import productData from './product';
import offerData from './offer';
import managerData from './manager';
import orderData from './order';
import ingredientData from './ingredient';
import purchaseOrderData from './purchaseOrders';
import reports from './reports';

export default function createReducer() {
    return combineReducers({
        authData, productData, offerData, orderData, managerData, ingredientData, purchaseOrderData,reports
    });
}